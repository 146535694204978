body,html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
    position: relative;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00ADC3 !important;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #00ADC3 !important;
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    background: #00ADC3 !important;
}

.ant-tabs-top >.ant-tabs-nav::before {
    border-bottom: 0;
}

.ant-select-multiple .ant-select-selection-item-remove {
    color: #F2F3F5 !important;
    opacity: 0.6;
}

.ant-select-multiple .ant-select-selection-overflow {
    @media (min-width: 512px) and (max-width: 1199px) {
        gap: 3px;
    }
}

.ant-select-multiple .ant-select-selection-overflow-item {
    @media (min-width: 512px) and (max-width: 1199px) {
        max-width: 55px;
    }
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #D4DCE5;
}

.ant-select .ant-select-arrow {
    color: #061E3B;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
}

.ant-select-single .ant-select-selector {
    color: #F2F3F5;
}
.ant-select-selector {
    color: #F2F3F5 !important;
    box-shadow: none !important;
}
.ant-select-selector:hover {
    border-color: #D4DCE5 !important;
}
.ant-select-selector:not(:focus) {
    border-color: #D4DCE5 !important;
}

.time-select.ant-select .ant-select-arrow {
    color: #F2F3F5;
}
.time-select.ant-select-selector {
    border-color: #F2F3F5 !important;
}
.time-select.ant-select-selector:hover {
    border-color: #F2F3F5 !important;
}